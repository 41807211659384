import { request } from 'network/request'

export function postEstimateData(parameter) {
  return request({
    url: '/estimate',
    method: 'post',
    data: parameter
  })
}

export function putEstimateData(parameter) {
  return request({
    url: '/estimate',
    method: 'put',
    data: parameter
  })
}

export function getEstimateDatas(parameter) {
  return request({
    url: '/estimate/' + parameter.user_name,
    method: 'get',
    data: parameter
  })
}

export function getEstimateDataAll(parameter) {
  return request({
    url: '/estimate/all/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function getEstimateDataOne(parameter) {
  return request({
    url: '/estimate/one/' + parameter.estimate_code,
    method: 'get',
    data: parameter
  })
}

export function searchEstimateData(parameter) {
  return request({
    url: '/estimate/search',
    method: 'get',
    params: parameter
  })
}

export function postEstimateDetailsData(parameter) {
  return request({
    url: '/estimate/detail',
    method: 'post',
    data: parameter
  })
}

export function putEstimateDetailsData(parameter) {
  return request({
    url: '/estimate/detail',
    method: 'put',
    data: parameter
  })
}

export function getEstimateDetailsDataAll(parameter) {
  return request({
    url: '/estimate/detail/all/' + parameter.estimate_code,
    method: 'get',
    data: parameter
  })
}

export function deleteEstimateDetail(parameter) {
  return request({
    url: '/estimate/detail/' + parameter.estimate_details_code,
    method: 'delete',
    data: parameter
  })
}

export function getEstimatePdf(parameter) {
  return request({
    url: '/estimate/pdf/' + parameter.estimate_code,
    method: 'get',
    data: parameter
  })
}
